import React from 'react'

import './MobileApp.css'

const MobileApp = () => {
  return (
    <section id="MobileApp">
      <div></div>
    </section>
  )
}

export default MobileApp
