import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import data from '../../data/courses'

import './CoursesGrid.css'

import Course from './Course'

const CoursesGrid = () => {
  const [filteredData, setFilteredData] = useState(data)

  const filterLanguage = (event) => {
    if (event.target.value === 'All') {
      setFilteredData(data)
    } else {
      const newCourses = data.filter((course) => {
        return course.language === event.target.value
      })

      setFilteredData(newCourses)
    }
  }

  return (
    <section id="CoursesGrid">
      <div className="CoursesGrid__container">
        <div className="CoursesGrid__container-heading">
          <h2>Upcoming Courses</h2>
          <p>Select from our upcoming courses below and enroll today.</p>
        </div>
        <div className="CoursesGrid__container-controls">
          <select name="language" onChange={filterLanguage}>
            <option value="All">All</option>
            <option value="English">English</option>
            <option value="Spanish">Spanish</option>
            <option value="German">German</option>
            <option value="Russian">Russian</option>
            <option value="Italian">Italian</option>
            <option value="French">French</option>
            <option value="Japanese">Japanese</option>
          </select>
        </div>
        <div className="CourseGrid__list">
          {filteredData.map((item, index) => (
            <Link to={item.slug} key={index}>
              <Course
                key={index}
                courseName={item.courseName}
                description={item.description}
                lessons={item.lessons}
                date={item.date}
                img={item.img}
              />
            </Link>
          ))}
        </div>
      </div>
    </section>
  )
}

export default CoursesGrid
