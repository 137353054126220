import {
  spanish,
  english,
  french,
  german,
  italian,
  japanese,
  russian,
} from './images'

const data = [
  {
    language: 'Spanish',
    img: spanish,
    courseName: 'Spanish - Introductory Spanish',
    description: 'Beginners Spanish A1 & A2',
    lessons: 12,
    date: 'January 2025',
    slug: '/members?spanish-intro-a1-a1',
    id: 1,
  },
  {
    language: 'Spanish',
    img: spanish,
    courseName: 'Spanish - 12 Week Intensive',
    description: 'Improvers Spanish A2 & A3',
    lessons: 36,
    date: 'January 2025',
    slug: '/members?spanish-12-week-a2-a3',
    id: 1,
  },
  {
    language: 'Spanish',
    img: spanish,
    courseName: 'Spanish - 12 Week Intensive',
    description: 'Intermediate Spanish B1 & B2',
    lessons: 36,
    date: 'January 2025',
    slug: '/members?spanish-12-week-b1-b2',
    id: 2,
  },
  {
    language: 'German',
    img: german,
    courseName: 'German - 12 Week Intensive',
    description: 'Improvers German A2 & A3',
    lessons: 36,
    date: 'January 2025',
    slug: '/members?german-12-week-a2-a3',
    id: 3,
  },
  {
    language: 'German',
    img: german,
    courseName: 'German - 12 Week Intensive',
    description: 'Intermediate German B2 & B3',
    lessons: 36,
    date: 'January 2025',
    slug: '/members?german-12-week-b2-b3',
    id: 3,
  },
  {
    language: 'Russian',
    img: russian,
    courseName: 'Russian - 4 Week Intro',
    description: 'Beginners Russian A1 & A2',
    lessons: 8,
    date: 'January 2025',
    slug: '/members?russian/a1-a2',
    id: 4,
  },
  {
    language: 'Russian',
    img: russian,
    courseName: 'Russian - 12 Week Intensive',
    description: 'Improvers Russian A2 & A3',
    lessons: 36,
    date: 'January 2025',
    slug: '/members?russian/a1-a2',
    id: 4,
  },
  {
    language: 'Russian',
    img: russian,
    courseName: 'Russian - 12 Week Intensive',
    description: 'Intermediate Russian A3 & B1',
    lessons: 36,
    date: 'January 2025',
    slug: '/members?Russian/a3-b1',
    id: 5,
  },
  {
    language: 'English',
    img: english,
    courseName: 'English - 12 Week Intensive',
    description: 'Intermediate B2 & B3',
    lessons: 36,
    date: 'January 2025',
    slug: '/members?english/b2z-b3',
    id: 6,
  },
  {
    language: 'English',
    img: english,
    courseName: 'English - 12 Week Intensive',
    description: 'Advanced English C1 & C2',
    lessons: 36,
    date: 'January 2025',
    slug: '/members?english/c1-c2',
    id: 7,
  },
  {
    language: 'French',
    img: french,
    courseName: 'French - 12 Week Intensive',
    description: 'Beginners French A1 & A2',
    lessons: 36,
    date: 'January 2025',
    slug: '/members?french/a1-a2',
    id: 7,
  },
  {
    language: 'French',
    img: french,
    courseName: 'French - 12 Week Intensive',
    description: 'Intermediate French B1 & B2',
    lessons: 36,
    date: 'January 2025',
    slug: '/members?french/b1-b2',
    id: 7,
  },
  {
    language: 'Italian',
    img: italian,
    courseName: 'Italian - 12 Week Intensive',
    description: 'Beginners Italian A1 & A2',
    lessons: 36,
    date: 'January 2025',
    slug: '/members?Italianh/a1-a2',
    id: 7,
  },
  {
    language: 'Italian',
    img: italian,
    courseName: 'Italian - 12 Week Intensive',
    description: 'Intermediate Italian B1 & B2',
    lessons: 36,
    date: 'January 2025',
    slug: '/members?Italian/b1-b2',
    id: 8,
  },
  {
    language: 'Japanese',
    img: japanese,
    courseName: 'Japanese - 12 Week Intensive',
    description: 'Intermediate Japanese A1 & A2',
    lessons: 36,
    date: 'February 2025',
    slug: '/members?Japanese/a1-a2',
    id: 8,
  },
  {
    language: 'Japanese',
    img: japanese,
    courseName: 'Japanese - 12 Week Intensive',
    description: 'Intermediate Japanese B1 & B2',
    lessons: 36,
    date: 'February 2025',
    slug: '/members?Japanese/b1-b2',
    id: 8,
  },
  {
    language: 'Spanish',
    img: spanish,
    courseName: 'Spanish - Introductory Spanish',
    description: 'Beginners Spanish A1 & A2',
    lessons: 12,
    date: 'April 2025',
    slug: '/members?spanish-intro-a1-a1',
    id: 1,
  },
  {
    language: 'Spanish',
    img: spanish,
    courseName: 'Spanish - 12 Week Intensive',
    description: 'Improvers Spanish A2 & A3',
    lessons: 36,
    date: 'April 2025',
    slug: '/members?spanish-12-week-a2-a3',
    id: 1,
  },
  {
    language: 'Spanish',
    img: spanish,
    courseName: 'Spanish - 12 Week Intensive',
    description: 'Intermediate Spanish B1 & B2',
    lessons: 36,
    date: 'April 2025',
    slug: '/members?spanish-12-week-b1-b2',
    id: 2,
  },
  {
    language: 'German',
    img: german,
    courseName: 'German - 12 Week Intensive',
    description: 'Improvers German A2 & A3',
    lessons: 36,
    date: 'April 2025',
    slug: '/members?german-12-week-a2-a3',
    id: 3,
  },
  {
    language: 'German',
    img: german,
    courseName: 'German - 12 Week Intensive',
    description: 'Intermediate German B2 & B3',
    lessons: 36,
    date: 'April 2025',
    slug: '/members?german-12-week-b2-b3',
    id: 3,
  },
  {
    language: 'Russian',
    img: russian,
    courseName: 'Russian - 4 Week Intro',
    description: 'Beginners Russian A1 & A2',
    lessons: 8,
    date: 'April 2025',
    slug: '/members?russian/a1-a2',
    id: 4,
  },
  {
    language: 'Russian',
    img: russian,
    courseName: 'Russian - 12 Week Intensive',
    description: 'Improvers Russian A2 & A3',
    lessons: 36,
    date: 'April 2025',
    slug: '/members?russian/a1-a2',
    id: 4,
  },
  {
    language: 'Russian',
    img: russian,
    courseName: 'Russian - 12 Week Intensive',
    description: 'Intermediate Russian A3 & B1',
    lessons: 36,
    date: 'April 2025',
    slug: '/members?Russian/a3-b1',
    id: 5,
  },
  {
    language: 'English',
    img: english,
    courseName: 'English - 12 Week Intensive',
    description: 'Intermediate B2 & B3',
    lessons: 36,
    date: 'April 2025',
    slug: '/members?english/b2z-b3',
    id: 6,
  },
  {
    language: 'English',
    img: english,
    courseName: 'English - 12 Week Intensive',
    description: 'Advanced English C1 & C2',
    lessons: 36,
    date: 'April 2025',
    slug: '/members?english/c1-c2',
    id: 7,
  },
  {
    language: 'French',
    img: french,
    courseName: 'French - 12 Week Intensive',
    description: 'Beginners French A1 & A2',
    lessons: 36,
    date: 'April 2025',
    slug: '/members?french/a1-a2',
    id: 7,
  },
  {
    language: 'French',
    img: french,
    courseName: 'French - 12 Week Intensive',
    description: 'Intermediate French B1 & B2',
    lessons: 36,
    date: 'April 2025',
    slug: '/members?french/b1-b2',
    id: 7,
  },
  {
    language: 'Italian',
    img: italian,
    courseName: 'Italian - 12 Week Intensive',
    description: 'Beginners Italian A1 & A2',
    lessons: 36,
    date: 'April 2025',
    slug: '/members?Italianh/a1-a2',
    id: 7,
  },
  {
    language: 'Italian',
    img: italian,
    courseName: 'Italian - 12 Week Intensive',
    description: 'Intermediate Italian B1 & B2',
    lessons: 36,
    date: 'April 2025',
    slug: '/members?Italian/b1-b2',
    id: 8,
  },
  {
    language: 'Japanese',
    img: japanese,
    courseName: 'Japanese - 12 Week Intensive',
    description: 'Intermediate Japanese A1 & A2',
    lessons: 36,
    date: 'May 2025',
    slug: '/members?Japanese/a1-a2',
    id: 8,
  },
  {
    language: 'Japanese',
    img: japanese,
    courseName: 'Japanese - 12 Week Intensive',
    description: 'Intermediate Japanese B1 & B2',
    lessons: 36,
    date: 'May 2025',
    slug: '/members?Japanese/b1-b2',
    id: 8,
  },
  {
    language: 'Spanish',
    img: spanish,
    courseName: 'Spanish - Introductory Spanish',
    description: 'Beginners Spanish A1 & A2',
    lessons: 12,
    date: 'September 2025',
    slug: '/members?spanish-intro-a1-a1',
    id: 1,
  },
  {
    language: 'Spanish',
    img: spanish,
    courseName: 'Spanish - 12 Week Intensive',
    description: 'Improvers Spanish A2 & A3',
    lessons: 36,
    date: 'September 2025',
    slug: '/members?spanish-12-week-a2-a3',
    id: 1,
  },
  {
    language: 'Spanish',
    img: spanish,
    courseName: 'Spanish - 12 Week Intensive',
    description: 'Intermediate Spanish B1 & B2',
    lessons: 36,
    date: 'September 2025',
    slug: '/members?spanish-12-week-b1-b2',
    id: 2,
  },
  {
    language: 'German',
    img: german,
    courseName: 'German - 12 Week Intensive',
    description: 'Improvers German A2 & A3',
    lessons: 36,
    date: 'September 2025',
    slug: '/members?german-12-week-a2-a3',
    id: 3,
  },
  {
    language: 'German',
    img: german,
    courseName: 'German - 12 Week Intensive',
    description: 'Intermediate German B2 & B3',
    lessons: 36,
    date: 'September 2025',
    slug: '/members?german-12-week-b2-b3',
    id: 3,
  },
  {
    language: 'Russian',
    img: russian,
    courseName: 'Russian - 4 Week Intro',
    description: 'Beginners Russian A1 & A2',
    lessons: 8,
    date: 'September 2025',
    slug: '/members?russian/a1-a2',
    id: 4,
  },
  {
    language: 'Russian',
    img: russian,
    courseName: 'Russian - 12 Week Intensive',
    description: 'Improvers Russian A2 & A3',
    lessons: 36,
    date: 'September 2025',
    slug: '/members?russian/a1-a2',
    id: 4,
  },
  {
    language: 'Russian',
    img: russian,
    courseName: 'Russian - 12 Week Intensive',
    description: 'Intermediate Russian A3 & B1',
    lessons: 36,
    date: 'September 2025',
    slug: '/members?Russian/a3-b1',
    id: 5,
  },
  {
    language: 'English',
    img: english,
    courseName: 'English - 12 Week Intensive',
    description: 'Intermediate B2 & B3',
    lessons: 36,
    date: 'September 2025',
    slug: '/members?english/b2z-b3',
    id: 6,
  },
  {
    language: 'English',
    img: english,
    courseName: 'English - 12 Week Intensive',
    description: 'Advanced English C1 & C2',
    lessons: 36,
    date: 'September 2025',
    slug: '/members?english/c1-c2',
    id: 7,
  },
  {
    language: 'French',
    img: french,
    courseName: 'French - 12 Week Intensive',
    description: 'Beginners French A1 & A2',
    lessons: 36,
    date: 'September 2025',
    slug: '/members?french/a1-a2',
    id: 7,
  },
  {
    language: 'French',
    img: french,
    courseName: 'French - 12 Week Intensive',
    description: 'Intermediate French B1 & B2',
    lessons: 36,
    date: 'September 2025',
    slug: '/members?french/b1-b2',
    id: 7,
  },
  {
    language: 'Italian',
    img: italian,
    courseName: 'Italian - 12 Week Intensive',
    description: 'Beginners Italian A1 & A2',
    lessons: 36,
    date: 'September 2025',
    slug: '/members?Italianh/a1-a2',
    id: 7,
  },
  {
    language: 'Italian',
    img: italian,
    courseName: 'Italian - 12 Week Intensive',
    description: 'Intermediate Italian B1 & B2',
    lessons: 36,
    date: 'September 2025',
    slug: '/members?Italian/b1-b2',
    id: 8,
  },
  {
    language: 'Japanese',
    img: japanese,
    courseName: 'Japanese - 12 Week Intensive',
    description: 'Intermediate Japanese A1 & A2',
    lessons: 36,
    date: 'October 2025',
    slug: '/members?Japanese/a1-a2',
    id: 8,
  },
  {
    language: 'Japanese',
    img: japanese,
    courseName: 'Japanese - 12 Week Intensive',
    description: 'Intermediate Japanese B1 & B2',
    lessons: 36,
    date: 'October 2025',
    slug: '/members?Japanese/b1-b2',
    id: 8,
  },
]

export default data
