import React from 'react'

import { Link } from 'react-router-dom'

import './BestSellers.css'

import teaching1 from '../../assets/teacher-aside.jpg'
import teaching2 from '../../assets/teacher-className.jpg'
import teaching3 from '../../assets/teacher-remote.jpg'
import teacher from '../../assets/giulia3.jpg'
import teacher2 from '../../assets/teacher.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faUsers } from '@fortawesome/free-solid-svg-icons'

function BestSellers() {
  return (
    <div id="bestSellers">
      <div className="bestSellers__container">
        <div className="bestSellers__container-heading">
          <h4>Get Tuition. Start Today.</h4>
          <h2>Our Current Most Popular Courses</h2>
          <p>
            These are our most popular language courses found among students.
          </p>
        </div>
        <div className="bestSellers__container-courses">
          <div className="bestSellers__container-courses-card">
            <img src={teaching1} alt="students learning" />
            <div className="bestSellers__container-courses-card-info">
              <div className="bestSellers__container-courses-card-teacher">
                <img src={teacher} alt="teacher" />
                <h5>Giulia Sci</h5>
              </div>
              <h3>Spanish - 12 Week Intensive</h3>
              <h4 style={{ paddingBottom: 5 }}>Beginner - A1 & A2</h4>
              <p style={{ height: 90 }}>
                This course will give you the solid foundations to be able to
                feel confident reading, writing, and speaking Spanish with the
                correct pronunciation.
              </p>
              <div className="bestSellers__container-courses-card-stats">
                <p>
                  <FontAwesomeIcon
                    icon={faBook}
                    style={{ paddingRight: '5px', color: '#fe4a55' }}
                  />
                  36 Lessons
                </p>
                <p>
                  <FontAwesomeIcon
                    icon={faUsers}
                    style={{ paddingRight: '5px', color: '#fe4a55' }}
                  />
                  25 Students
                </p>
              </div>
            </div>
          </div>
          <div className="bestSellers__container-courses-card">
            <img src={teaching2} alt="students learning" />
            <div className="bestSellers__container-courses-card-info">
              <div className="bestSellers__container-courses-card-teacher">
                <img src={teacher} alt="teacher" />
                <h5>Giulia Sci</h5>
              </div>
              <h3>English - 12 Week Intensive</h3>
              <h4>Intermediate - B2 & B3</h4>
              <p style={{ height: 90 }}>
                This course will not only progress your English compentency but
                also help you start sounding like a native speaker.
              </p>
              <div className="bestSellers__container-courses-card-stats">
                <p>
                  <FontAwesomeIcon
                    icon={faBook}
                    style={{ paddingRight: '5px', color: '#fe4a55' }}
                  />
                  36 Lessons
                </p>
                <p>
                  <FontAwesomeIcon
                    icon={faUsers}
                    style={{ paddingRight: '5px', color: '#fe4a55' }}
                  />
                  20 Students
                </p>
              </div>
            </div>
          </div>
          <div className="bestSellers__container-courses-card">
            <img src={teaching3} alt="students learning" />
            <div className="bestSellers__container-courses-card-info">
              <div className="bestSellers__container-courses-card-teacher">
                <img src={teacher2} alt="teacher" />
                <h5>Alexei Federov</h5>
              </div>
              <h3>Russian - 4 Week Intro</h3>
              <h4 style={{ paddingBottom: 5 }}>Beginner - A1 & A2</h4>
              <p style={{ height: 90 }}>
                Read and pronounce Russian characters (Cyrillic), and learn the
                basics of the language so you can speak the basics with
                confidence.
              </p>
              <div className="bestSellers__container-courses-card-stats">
                <p>
                  <FontAwesomeIcon
                    icon={faBook}
                    style={{ paddingRight: '5px', color: '#fe4a55' }}
                  />
                  8 Lessons
                </p>
                <p>
                  <FontAwesomeIcon
                    icon={faUsers}
                    style={{ paddingRight: '5px', color: '#fe4a55' }}
                  />
                  25 Students
                </p>
              </div>
            </div>
          </div>
        </div>
        <p>
          Want to learn more ? <Link to="/members">Join now</Link> to enroll in
          one of our courses.
        </p>
      </div>
    </div>
  )
}

export default BestSellers
