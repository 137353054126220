import React from 'react'

import { Link } from 'react-router-dom'

import './HeroLanding.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

import HeroImage from '../../assets/hero-img.png'
import Floater1 from '../../assets/floater1.png'
import Floater2 from '../../assets/floater2.png'
import Floater3 from '../../assets/floater3.png'
import Floater4 from '../../assets/floater4.png'
import Floater5 from '../../assets/floater5.png'

function HeroLanding() {
  return (
    <div id="hero">
      <img src={Floater1} alt="abstract scribble" />
      <img src={Floater2} alt="abstract scribble" />
      <img src={Floater3} alt="abstract scribble" />
      <img src={Floater4} alt="abstract scribble" />
      <img src={Floater5} alt="abstract scribble" />
      <div className="hero__container">
        <div className="hero__container-left">
          <h1>Learn languages without memorization! </h1>
          <p>
            Our language courses will have you effortly speaking with natives
            like a pro in no time.
          </p>
          <Link to="/members">
            <div className="hero__container-right-button">
              <FontAwesomeIcon icon={faUser} />

              <p>Start Today</p>
            </div>
          </Link>
        </div>
        <div className="hero__container-right">
          <img src={HeroImage} alt="seated studying" />
        </div>
      </div>
    </div>
  )
}

export default HeroLanding
