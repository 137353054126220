import React from 'react'
import { Link } from 'react-router-dom'

import './Footer.css'

import androidStore from '../../assets/google-button.png'
import appleStore from '../../assets/download.png'

function Footer() {
  const current = new Date()
  const year = `${current.getFullYear()}`

  return (
    <footer id="footer">
      <div className="footer__container">
        <div className="footer__container-top">
          <div className="footer__container-about">
            <h3>
              <span>L</span>ondon <span>L</span>anguage <span>S</span>chool
            </h3>
            <p>
              Working to bring significant changes in online-based learning by
              doing extensive research for course curriculum preparation,
              student engagements, and looking forward to the flexible
              education!
            </p>
          </div>
          <div className="footer__container-explore">
            <h3>Explore</h3>
            <Link to="/#" style={{ color: 'white' }}>
              <p>Home</p>
            </Link>
            <Link to="/about" style={{ color: 'white' }}>
              <p>About</p>
            </Link>
            <Link to="/languages" style={{ color: 'white' }}>
              <p>Languages</p>
            </Link>
            <Link to="/Courses" style={{ color: 'white' }}>
              <p>Courses</p>
            </Link>
            {/* <p>Lessons</p> */}
          </div>
          <div className="footer__container-app">
            <h3>Download Our App</h3>
            <p>
              Download the London Language School App from either Google Play or
              the App Store today!
            </p>

            <div>
              <img src={appleStore} alt="ios store logo" className="app" />
              <img
                src={androidStore}
                alt="google play store logo"
                className="app"
              />
            </div>
          </div>
        </div>
        <div className="footer__container-bottom">
          <p>© {year} London Language School</p>
          <p>
            <Link
              to="/privacy-policy"
              style={{ color: '#fe4a55', paddingRight: 7 }}
            >
              Privacy Policy
            </Link>{' '}
            |{' '}
            <Link
              to="/terms-and-conditions"
              style={{ color: '#fe4a55', paddingLeft: 7 }}
            >
              Terms & Conditions
            </Link>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
