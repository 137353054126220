import React from 'react'

import { Link } from 'react-router-dom'

import './LanguageList.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import English from '../../assets/languages/English.webp'
import Spanish from '../../assets/languages/Spanish.webp'
import French from '../../assets/languages/French.webp'
import Italian from '../../assets/languages/Italian.webp'
import Portugese from '../../assets/languages/Portuguese.webp'
import Russian from '../../assets/languages/Russian.webp'
import German from '../../assets/languages/German.webp'
import Japanese from '../../assets/languages/Japanese.webp'

const LanguageList = () => {
  return (
    <section id="LanguageList">
      <div className="LanguageList__container">
        <div className="LanguageList__container-third">
          {/* Language */}
          <div className="LanguageList__container-info">
            <img src={English} alt="English"></img>
            <h2>English</h2>
            <Link to="/courses?english">
              <div className="LanguageList__english button">
                View Courses
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </Link>
          </div>
          {/* Language */}
          <div className="LanguageList__container-info">
            <img src={Spanish} alt="Spanish"></img>
            <h2>Spanish</h2>
            <Link to="/courses?spanish">
              <div className="LanguageList__Spanish button">
                View Courses
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </Link>
          </div>
          {/* Language */}
          <div className="LanguageList__container-info">
            <img src={French} alt="French"></img>
            <h2>French</h2>
            <Link to="/courses?french">
              <div className="LanguageList__French button">
                View Courses
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </Link>
          </div>
        </div>
        <div className="LanguageList__container-third">
          {/* Language */}
          <div className="LanguageList__container-info">
            <img src={Italian} alt="Italian"></img>
            <h2>Italian</h2>
            <Link to="/courses?italian">
              <div className="LanguageList__Italian button">
                View Courses
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </Link>
          </div>
          {/* Language */}
          <div className="LanguageList__container-info">
            <img src={Portugese} alt="Portuguese"></img>
            <h2>Portuguese</h2>
            <Link to="/courses?portuguese">
              <div className="LanguageList__Portuguese button">
                View Courses
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </Link>
          </div>
          {/* Language */}
          <div className="LanguageList__container-info">
            <img src={Russian} alt="Russian"></img>
            <h2>Russian</h2>
            <Link to="/courses?russian">
              <div className="LanguageList__Russian button">
                View Courses
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </Link>
          </div>
        </div>
        <div className="LanguageList__container-third">
          {/* Language */}
          <div className="LanguageList__container-info">
            <img src={German} alt="German"></img>
            <h2>German</h2>
            <Link to="/courses?german">
              <div className="LanguageList__German button">
                View Courses
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </Link>
          </div>
          {/* Language */}
          <div className="LanguageList__container-info">
            <img src={Japanese} alt="Japanese"></img>
            <h2>Japanese</h2>
            <Link to="/courses?japanese">
              <div className="LanguageList__Japanese button">
                View Courses
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LanguageList
