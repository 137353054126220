import React from 'react'

import LanguageList from '../components/Languages/LanguagesList'
import Instructors from '../components/Common/Instructors'
import Levels from '../components/Common/Levels'
import WhyUs from '../components/Home/WhyUs'
import Cta from '../components/Common/Cta'

const Languages = () => {
  return (
    <div>
      <Levels />
      <LanguageList />
      <WhyUs />
      <Instructors />
      <Cta />
    </div>
  )
}

export default Languages
