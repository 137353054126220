import React from 'react'

import Distance from '../components/About/Distance'
import Instructors from '../components/Common/Instructors'
import Cta from '../components/Common/Cta'
import Methods from '../components/About/Methods'
import MobileApp from '../components/Common/MobileApp'

function About() {
  return (
    <div>
      <Methods />
      <Distance />
      <Instructors />
      <MobileApp />
      <Cta />
    </div>
  )
}

export default About
