import React from 'react'

import './Course.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faCalendar } from '@fortawesome/free-solid-svg-icons'

const Course = ({ courseName, description, lessons, date, img }) => {
  return (
    <div id="course">
      <div className="course__imageContainer">
        <img
          className="course__imageContainer-image"
          src={img}
          alt="language"
        />
      </div>
      <div className="course__details">
        <h3 className="course__name">{courseName}</h3>
        <p>{description}</p>
        <div className="course__stats">
          <p>
            <FontAwesomeIcon
              icon={faBook}
              style={{ paddingRight: '5px', color: '#fe4a55' }}
            />
            {lessons} Lessons
          </p>
          <p>
            <FontAwesomeIcon
              icon={faCalendar}
              style={{ paddingRight: '5px', color: '#fe4a55' }}
            />
            {date}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Course
