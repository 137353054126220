import React, { useEffect, useState } from 'react'

import './Register&Login.css'

const Register = (props) => {
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const [message, setMessage] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    if (pass.length > 8) {
      setMessage(
        'Your registration was sucessful. please confirm your e-mail to login.'
      )
    } else if (pass.length < 8) {
      setMessage('Password length must be atleast 8 characters')
    }

    console.log(message)
  }

  useEffect(() => {}, [message])

  return (
    <div className="form__container">
      {' '}
      <h2>Register</h2>
      <form className="register-form">
        <label htmlFor="firstName">First Name</label>
        <input
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          name="firstName"
          id="firstName"
          placeholder="First Name"
          required
        />
        <label htmlFor="lastName">Last Name</label>
        <input
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          name="lastName"
          id="lastName"
          placeholder="Last Name"
          required
        />
        <label htmlFor="email">Email</label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="youremail@gmail.com"
          id="email"
          required
          name="email"
        />
        <label htmlFor="password">Password</label>
        <input
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          type="password"
          placeholder="********"
          id="password"
          name="password"
          minlength="8"
          required
        />
        <span>{message}</span>
        <button className="herobutton" type="submit" onClick={handleSubmit}>
          Register
        </button>
      </form>
      <div>
        Already have an account? |{' '}
        <span onClick={() => props.onFormSwitch('login')}>Login here.</span>
      </div>
    </div>
  )
}

export default Register
