export const english =
  'https://firebasestorage.googleapis.com/v0/b/london-language-school.appspot.com/o/English.webp?alt=media&token=5f71b1e5-9655-405b-8cdd-e5fbeb5d6560'
export const spanish =
  'https://firebasestorage.googleapis.com/v0/b/london-language-school.appspot.com/o/Spanish.webp?alt=media&token=bd0d923b-23c8-4313-b6ec-c7bb5f179efd'
export const french =
  'https://firebasestorage.googleapis.com/v0/b/london-language-school.appspot.com/o/French.webp?alt=media&token=26c303ff-ea00-456a-b692-6d8740e72af0'
export const german =
  'https://firebasestorage.googleapis.com/v0/b/london-language-school.appspot.com/o/German.webp?alt=media&token=27435a53-e9b2-4687-8ad4-ae19ce323b45'
export const italian =
  'https://firebasestorage.googleapis.com/v0/b/london-language-school.appspot.com/o/Italian.webp?alt=media&token=b382ec76-7c0e-4999-9a2b-5aefa8870051'
export const japanese =
  'https://firebasestorage.googleapis.com/v0/b/london-language-school.appspot.com/o/Japanese.webp?alt=media&token=c2000295-d503-4cb5-9c32-1af3cf53905e'
export const portuguese =
  'https://firebasestorage.googleapis.com/v0/b/london-language-school.appspot.com/o/Portuguese.webp?alt=media&token=6eb5d136-3903-46ef-9f7d-d07c5307da53'
export const russian =
  'https://firebasestorage.googleapis.com/v0/b/london-language-school.appspot.com/o/Russian.webp?alt=media&token=e0eca616-6053-48a5-b5c3-a78c1ff61e6a'
