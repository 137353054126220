import React, { useState } from 'react'

import './Register&Login.css'

const ResetPassword = (props) => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    setMessage('A password recovery e-mail has been sent')
  }

  return (
    <div className="form__container">
      <h2>Reset Password</h2>
      <form className="reset-form" onSubmit={handleSubmit}>
        <label htmlFor="email">Email</label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="youremail@gmail.com"
          id="email"
          name="email"
          required
        />
        <span>{message}</span>
        <button className="herobutton" type="submit" onClick={handleSubmit}>
          Reset Password
        </button>
      </form>
      <div>
        <span onClick={() => props.onFormSwitch('login')}>Login</span> |{' '}
        <span onClick={() => props.onFormSwitch('register')}>Register</span>
      </div>
    </div>
  )
}

export default ResetPassword
