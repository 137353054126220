import React from 'react'
import './Courses.css'

import { useNavigate } from 'react-router-dom'

import Course1 from '../../assets/course1.png'
import Course2 from '../../assets/course2.png'
import Course3 from '../../assets/course3.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

function Courses() {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/courses')
  }

  return (
    <div id="courses">
      <div className="courses__container">
        <div className="courses__container-text">
          <h4>Welcome To London Language School</h4>
          <h2>Some of Our Popular Language Courses</h2>
          <p>
            We follow the Common European Framework of Reference for Languages:
            Learning, Teaching, Assessment. CEFR provides 6 reference levels to
            guage your language proficiency.
          </p>
        </div>
        <div className="courses__container-languages">
          <div className="courses__container-languages-container">
            <img src={Course1} alt="student studying" />
            <h3>Spanish</h3>
            <p>
              Our Spanish coureses will provide you with a stress-free practical
              lessons providing you with all you need to speak one of the most
              spoken languages in the world in real-life situations.
            </p>
            <div onClick={handleClick}>
              <FontAwesomeIcon icon={faArrowRight} />
              View More
            </div>
          </div>
          <div className="courses__container-languages-container">
            <img src={Course2} alt="student studying" />
            <h3>Japanese</h3>
            <p>
              From finding your way around crazy Shinjuku to ordering
              Okonomiyaki pancakes, shopping, greeting new friends or connecting
              with family, you’ll learn Japanese how it’s used by native
              speakers in daily life.
            </p>
            <div onClick={handleClick}>
              <FontAwesomeIcon icon={faArrowRight} />
              View More
            </div>
          </div>
          <div className="courses__container-languages-container">
            <img src={Course3} alt="student studying" />
            <h3>French</h3>
            <p>
              Delicious food. Stunning cities. A romantic feel. There are 101
              reasons to go to France. No matter your motivation, learn to speak
              French in countless scenarios from our lessons with native level
              teachers.
            </p>
            <div onClick={handleClick}>
              <FontAwesomeIcon icon={faArrowRight} />
              View More
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Courses
