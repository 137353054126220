import React from 'react'

import './Levels.css'

import floater1 from '../../assets/floater3.png'
import floater2 from '../../assets/floater4.png'

const Levels = () => {
  return (
    <section id="levels">
      <div className="levels__floater">
        <img
          src={floater1}
          className="levels__floater-left"
          alt="abstract scribble"
        />
        <img
          src={floater2}
          className="levels__floater-right"
          alt="another abstract scribble"
        />
      </div>
      <div className="levels__container">
        <div className="levels__container-hero">
          <h2>Learn No Matter Your Level</h2>
          <p>
            Our courses are for everyone regardless of level. We follow the
            Common European Framework Reference for Languages(CEFR). <br />
            <br />
            So whether you're a starting out completely new a language, or
            already proficient looking to take the next steps in your language
            journey, you will be able to easily progress.
            <br />
            <br />
            Unsure of what level your are? Sign Up and our team will be able to
            advise the best course for you.
          </p>
        </div>
        <div className="levels__container-details"></div>
      </div>
    </section>
  )
}

export default Levels
