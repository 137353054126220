import React, { useState } from 'react'

import './Register&Login.css'

const Login = (props) => {
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <div className="form__container">
      <h2>Login</h2>
      <form className="login-form" onSubmit={handleSubmit}>
        <label htmlFor="email">Email</label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="youremail@gmail.com"
          id="email"
          name="email"
          required
        />
        <label htmlFor="password">Password</label>
        <input
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          type="password"
          placeholder="********"
          id="password"
          name="password"
          required
        />
        <span onClick={() => props.onFormSwitch('reset')}>
          Lost your password?
        </span>
        <button className="herobutton" type="submit" onClick={handleSubmit}>
          Log In
        </button>
      </form>
      <div>
        Don't have an account? |{' '}
        <span onClick={() => props.onFormSwitch('register')}>
          Register here.
        </span>
      </div>
    </div>
  )
}

export default Login
