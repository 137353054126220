import { Route, Routes, BrowserRouter, useLocation } from 'react-router-dom'
import { useEffect } from 'react'

import Home from '../src/pages/Home'
import NavBar from './components/Common/NavBar'
import About from '../src/pages/About'
import Courses from '../src/pages/Courses'
import Lessons from '../src/pages/Lessons'
import Languages from '../src/pages/Languages'
import Members from '../src/pages/Members'
import Footer from '../src/components/Common/Footer'
import Privacy from '../src/pages/Privacy'
import Terms from '../src/pages/Terms'

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/about" element={<About />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/lessons" element={<Lessons />} />
          <Route path="/members" element={<Members />} />
          <Route path="/languages" element={<Languages />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
