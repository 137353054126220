import React from 'react'

import './Testimonials.css'

import student from '../../assets/student.jpeg'

function Testimonials() {
  return (
    <div id="testimonials">
      <div className="testimonials__container">
        <div className="testimonials__container-card">
          <p>
            Giulia is a fantastic teacher and is able to make you feel very
            comfortable learning and not worrying about making mistakes. The
            focus on being understood and being able to express yourself first
            then expanding out means I was able to start using my Spanish
            immediately.
          </p>
          <div className="testimonials__container-card-person">
            <img src={student} alt="happy student" />
            <div>
              <h3>Katrina Taylor</h3>
              <p>Spanish Language Student</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
