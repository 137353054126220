import React from 'react'

import './Distance.css'

import img2 from '../../assets/about/about2.png'
import img3 from '../../assets/about/about3.png'
import img4 from '../../assets/about/about4.png'

const Distance = () => {
  return (
    <section id="distance">
      <div className="distance__left">
        <img src={img2} alt="group of students" />
        <img src={img4} alt="another group of students learning" />
        <img src={img3} alt="a happy student learning" />
      </div>
      <div className="distance__right">
        <div className="distance__right-text">
          <h4>100% Distance Learning</h4>
          <h2>Remote classes As A Standard</h2>
          <p>
            All our lessons our are now over video call in group a setting with
            one teacher and multiple asssistants. <br />
            <br />
            Students are provided an overview of the focus of that lesson,
            covering some basics, before moving onto practicing with the
            assistants in breakout roomss.
          </p>
        </div>
        {/* <div className="distance__right-points">
          <ul>
            <li>Expert Instruction</li>
            <li>Learn From Anywhere</li>
          </ul>
          <ul>
            <li>Practice With Natives</li>
            <li>Progress With A Community</li>
          </ul>
        </div> */}
      </div>
    </section>
  )
}

export default Distance
