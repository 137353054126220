import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import './NavBar.css'

import Hamburger from 'hamburger-react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faSearch,
  faArrowAltCircleUp,
  faLeaf,
} from '@fortawesome/free-solid-svg-icons'

function NavBar() {
  const [showButton, setShowButton] = useState(false)

  const [isOpen, setIsOpen] = useState(false)

  const [menu_class, setMenuClass] = useState('menu hidden')

  let [searchParams, setSearchParams] = useSearchParams()
  let [query, setQuery] = useState()
  let [search, setSearch] = useState('')

  const handleSubmit = () => {
    // setSearchParams({ query })
    console.log(query)
    navigation(`/members?results=${search}`)
  }

  const navigation = useNavigate()

  const closeMenu = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (isOpen) {
      setMenuClass('menu visible')
    } else {
      setMenuClass('menu hidden')
    }
  }, [isOpen])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 300) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    })
  })

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const current = new Date()
  const year = `${current.getFullYear()}`

  return (
    <>
      <nav id="navbar">
        <div className="nav__left">
          <Link to="/">
            <div className="nav__logo">
              <span>L</span>ondon <span>L</span>anguage <span>S</span>chool
            </div>
          </Link>
        </div>
        <div className="nav__middle">
          <div className="nav__middle--search-container">
            <form style={{ display: 'flex' }}>
              <input
                className="nav__middle--search-bar"
                placeholder="Search"
                value={query}
                onChange={(e) => setSearch(e.target.value)}
              />
              <FontAwesomeIcon
                type="submit"
                onClick={handleSubmit}
                icon={faSearch}
                style={{
                  left: -35,
                  top: 15,
                  position: 'relative',
                  color: '#fe4a55',
                  cursor: 'pointer',
                }}
              />
            </form>
          </div>
          <div class="nav__middle-links">
            <ul className="nav__middle--ul">
              <li className="nav__middle--link">
                <Link to="/">Home</Link>
              </li>
              <li className="nav__middle--link">
                <Link to="/about">About</Link>
              </li>
              <li className="nav__middle--link">
                <Link to="/languages">Languages</Link>
              </li>
              <li className="nav__middle--link">
                <Link to="/courses">Courses</Link>
              </li>
            </ul>
          </div>

          <div className="hamburgerMenu">
            <Hamburger
              toggled={isOpen}
              toggle={setIsOpen}
              color="#fe4a55"
              class="top"
            />
          </div>
        </div>

        <div className="nav__right">
          <Link to="/members">
            <div className="nav__right--button">
              <FontAwesomeIcon icon={faUser} />
              <p>Login/Register</p>
            </div>
          </Link>
        </div>

        {showButton && (
          <FontAwesomeIcon
            onClick={scrollToTop}
            icon={faArrowAltCircleUp}
            style={{
              bottom: 20,
              right: 20,
              cursor: 'pointer',
              position: 'fixed',
              color: '#fe4a55',
              height: 50,
              backgroundColor: 'white',
              borderRadius: 50,
              zIndex: 3,
            }}
          />
        )}
      </nav>
      <div className={menu_class} onClick={closeMenu}>
        <Hamburger toggled={isOpen} toggle={setIsOpen} color="#fe4a55" />
        <div className="sidemenu__container">
          <div className="sidemenu__links" onClick={scrollToTop}>
            <ul className="sidemenu__links--ul">
              <li className="nav__middle--link">
                <Link to="/">Home</Link>
              </li>
              <li className="nav__middle--link">
                <Link to="/about">About</Link>
              </li>
              <li className="nav__middle--link">
                <Link to="/languages">Languages</Link>
              </li>
              <li className="nav__middle--link">
                <Link to="/courses">Courses</Link>
              </li>
            </ul>
          </div>
          <div className="sidemenu__login">
            {' '}
            <Link to="/members">
              <div className="nav__right--button">
                <FontAwesomeIcon icon={faUser} />
                <p>Login/Register</p>
              </div>
            </Link>
          </div>
          <div className="sidemenu__footer">
            <p>© {year} London Language School</p>
            <p>
              <Link
                to="/privacy-policy"
                style={{ color: '#fe4a55', paddingRight: 7 }}
              >
                Privacy Policy
              </Link>{' '}
              |{' '}
              <Link
                to="/terms-and-conditions"
                style={{ color: '#fe4a55', paddingLeft: 7 }}
              >
                Terms & Conditions
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default NavBar
