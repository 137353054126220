import React from 'react'

import { Link } from 'react-router-dom'

import './WhyUs.css'

import LeftImage from '../../assets/whyus.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faChalkboardTeacher,
  faSackDollar,
  faRocket,
  faInfinity,
} from '@fortawesome/free-solid-svg-icons'

function WhyUs() {
  return (
    <div id="whyUs">
      <div className="whyUs__container">
        <div className="whyUs__container-left">
          <img src={LeftImage} alt="happy girl" />
        </div>
        <div className="whyUs__container-right">
          <h4>Why choose us</h4>
          <h2>Language Lessons To Help You Explore The World</h2>
          <p>
            We understand better that online-based learning can make a
            significant change to reach students from all over the world! Giving
            options to learn better always can offer the best outcomes!
          </p>
          <div className="whyUs__container-right-points">
            <ul>
              <li>
                <span>
                  <FontAwesomeIcon
                    icon={faChalkboardTeacher}
                    style={{ color: '#fe4a55' }}
                  />
                  <p>Remote Classes With Naitive Speakers</p>
                </span>
              </li>
              <li>
                <span>
                  <FontAwesomeIcon
                    icon={faRocket}
                    style={{ color: '#fe4a55' }}
                  />
                  <p>Natural method means fastests progress</p>
                </span>
              </li>
              <li>
                <span>
                  {' '}
                  <FontAwesomeIcon
                    icon={faSackDollar}
                    style={{ color: '#fe4a55' }}
                  />
                  <p>Cost Effective</p>
                </span>
              </li>
              <li>
                <span>
                  <FontAwesomeIcon
                    icon={faInfinity}
                    style={{ color: '#fe4a55' }}
                  />
                  <p>Lifetime support from teachers</p>
                </span>
              </li>
            </ul>
          </div>
          <Link to="/members">
            <div className="hero__container-right-button">
              <FontAwesomeIcon icon={faUser} />

              <p>Join For Free</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default WhyUs
