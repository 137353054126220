import React from 'react'

import HeroLanding from '../components/Home/HeroLanding'
import Courses from '../components/Home/Courses'
import WhyUs from '../components/Home/WhyUs'
import Testimonials from '../components/Home/Testimonials'
import BestSellers from '../components/Home/BestSellers'
import Cta from '../components/Common/Cta'

function Home() {
  return (
    <div>
      <HeroLanding />
      <Courses />
      <WhyUs />
      <Testimonials />
      <BestSellers />
      <Cta />
    </div>
  )
}

export default Home
