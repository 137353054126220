import React from 'react'

import './terms.css'

function Terms() {
  return (
    <main id="terms">
      <section class="terms__container">
        <div class="terms__container-heading">
          <h1>Terms & Conditions</h1>
        </div>
        <div class="terms__container-details">
          <h3>Booking Conditions </h3>{' '}
          <p>
            Courses take place either online via video call or during our live
            classes. Please check specific course page for exact details. Class
            times and course content are as advertised on the website. The
            minimum age for courses is 18 years. Cancellations made by students
            with less than one full week’s notice before the start of a course
            will be subject to a 100% cancellation charge.
          </p>
          <p>
            Loyalty rewards and promotions must be used at the time of booking
            and cannot be applied retrospectively. If a student begins a course
            and feels that they need to change to a higher or lower level, they
            will need to let us know after the first week of class and before
            the second week begins, in order for us to try and make the
            appropriate arrangements.
          </p>
          <p>
            If for unforeseen circumstances students are unable to attend their
            course anymore, it is possible to postpone the booking to a later
            course.
          </p>
          <p>
            This however needs to be done in the first week of the course. It is
            not possible to postpone the course later than this. You cannot
            postpone more than once.
          </p>
          <p>
            Please note that final confirmation of participation in the course
            will be subject to the demand for each language and level. In the
            unlikely event of International House London being unable to run a
            student’s chosen course, they will be offered an alternative course
            at a discounted rate or a full refund of the fees.
          </p>
        </div>
      </section>
    </main>
  )
}

export default Terms
