import React, { useState } from 'react'

import './Members.css'

import Login from '../components/Common/Login'
import Register from '../components/Common/Register'
import ResetPassword from '../components/Common/ResetPassword'

function Members() {
  const [currentForm, setCurrentForm] = useState('login')

  const toggleForm = (formName) => {
    setCurrentForm(formName)
  }

  return (
    <section id="Members">
      <div className="Members__container">
        {currentForm === 'login' ? (
          <Login onFormSwitch={toggleForm} />
        ) : currentForm === 'register' ? (
          <Register onFormSwitch={toggleForm} />
        ) : (
          <ResetPassword onFormSwitch={toggleForm} />
        )}
      </div>
    </section>
  )
}

export default Members
