import React from 'react'

import { Link } from 'react-router-dom'

import './Cta.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

import Floater1 from '../../assets/about/floater1.png'
import Floater2 from '../../assets/about/floater2.png'

const Cta = () => {
  return (
    <section id="cta">
      <img src={Floater1} alt="abstract scribble" />
      <img src={Floater2} alt="abstract scribble" />
      <div className="cta__container">
        <h4>Get started today</h4>
        <h2>Unlock your language skills today!</h2>
        <p>
          Join today to enroll in one of our language couress and recieve a free
          language assessment with an instructor to assess your level.
        </p>
        <Link to="/members">
          <div
            className="hero__container-right-button"
            style={{ marginTop: 20 }}
          >
            {' '}
            <FontAwesomeIcon icon={faUser} />
            <p>Sign Up Today</p>
          </div>
        </Link>
      </div>
    </section>
  )
}

export default Cta
