import React from 'react'

import BestSellers from '../components/Home/BestSellers'
import CoursesGrid from '../components/Courses/CoursesGrid'

function Courses() {
  return (
    <section style={{ backgroundColor: '#f5f7fa' }}>
      <CoursesGrid />
      <BestSellers />
    </section>
  )
}

export default Courses
