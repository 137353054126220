import React from 'react'

import './Success.css'

const Success = () => {
  return (
    <div id="Success">
      <div className="Success__container">
        <h4>Achieve Your Language Goals</h4>
        <h2>One To One Lessons</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
          ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel
          facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
          sed tempor incididunt ut labore et dolore.
        </p>
      </div>
    </div>
  )
}

export default Success
