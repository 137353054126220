import React from 'react'

import './Methods.css'

import img from '../../assets/about/information-img.77c055a6.png'
import img2 from '../../assets/floater1.png'
import img3 from '../../assets/floater2.png'

const Methods = () => {
  return (
    <section id="Methods">
      <div className="methods__floaters">
        <img src={img2} className="right" alt="abstract scribble" />
        <img src={img3} className="left" alt="abstract scribble" />
      </div>
      <div className="methods__container">
        <div className="methods__container-left">
          <h4>Learn Languages Effortlessly</h4>
          <h2>Why our methods are so effective</h2>
          <p>
            We use the natural method which is the same way children acquire
            their first language focusing on immersive input, immeidate
            practice, and a stress free environment not worrying if we make
            mistakes.
          </p>
          <br></br>
          <p>
            The advantage of this is the focus is not on memorisation but
            instead speaking the language immediately with the most common words
            and phrases first providing a strong foudation and confidence to
            practice which strengthens memory recall.
          </p>
        </div>
        <div className="methods__container-right">
          <img src={img} alt="cartoon of happy student" />
        </div>
      </div>
    </section>
  )
}

export default Methods
