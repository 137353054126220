import React from 'react'

import './Instructors.css'

import { Link } from 'react-router-dom'

import img1 from '../../assets/giulia sci.jpeg'
import img2 from '../../assets/teacher.jpg'

const Instructors = () => {
  return (
    <section id="instructors">
      <div className="instructors__heading">
        <h4>Meet Our Team</h4>
        <h2>Our World-class Instructors</h2>
        <p>
          Our lead instructors have over 10 years of teaching languages each.
          Assistant instructors during live lessons change from time to time.
        </p>
      </div>
      <div className="instructors__info">
        <div className="instructors__info-left">
          <img src={img1} />
          <div>
            <h3 style={{ padding: 5 }}>Giulia Sci</h3>
            <h4 style={{ padding: 5 }}>Founder & Lead Instructor</h4>
            <p style={{ padding: 5 }}>
              The ultimate polyglot, Giulia speaks 7 languages (Italian,
              English, French, Spanish, Portugese, Russian, & German) and
              founded London Language School to help people learn languages all
              over the world with the natural method.
            </p>
          </div>
        </div>
        <div className="instructors__info-right">
          <img src={img2} />

          <div className="instructors__info-right-text">
            <h3 style={{ padding: 5 }}>Alexei Federov</h3>
            <h4 style={{ padding: 5 }}>Course Architect & Instructor</h4>
            <p style={{ padding: 5 }}>
              Alexi has taught languages all over the world, creates our course
              architecture, is lead instructor, and loves how languages can
              bring people closer together.
            </p>
          </div>
        </div>
      </div>
      <div className="instructors__bottom">
        <p>
          Get started today! <Link to="/members">Join now</Link> to enroll in
          one of our courses.
        </p>
      </div>
    </section>
  )
}

export default Instructors
